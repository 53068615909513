<template>
  <div class="example-full">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Drop files to upload</h3>
    </div>
    <div class="upload">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>#</th>

              <th>Name</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody class="tbody">
            <tr v-if="!files.length">
              <td colspan="9">
                <div class="text-center p-5">
                  <h4>You can drag and drop your CV here</h4>
                  <br />
                  <h6>
                    Drag the CV you want to upload here and press the Upload
                    button
                  </h6>
                  <br />
                  <label :for="name" class="btn btn-lg btn-primary"
                    >Select Files</label
                  >
                </div>
              </td>
            </tr>
            <tr v-for="(file, index) in files" :key="file.id">
              <td>{{ index }}</td>

              <td>
                <div class="filename">
                  {{ file.name }}
                </div>
                <div
                  class="progress"
                  v-if="file.active || file.progress !== '0.00'"
                >
                  <div
                    :class="{
                      'progress-bar': true,
                      'progress-bar-striped': true,
                      'bg-danger': file.error,
                      'progress-bar-animated': file.active,
                    }"
                    role="progressbar"
                    :style="{ width: file.progress + '%' }"
                  >
                    {{ Math.round(file.progress) }}%
                  </div>
                </div>
              </td>

              <td>{{ fileSizePipe(file.size) || 0 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="example-foorer">
        <div class="footer-status float-right"></div>
        <div class="btn-group">
          <file-upload
            class="btn btn-primary dropdown-toggle"
            :post-action="postAction"
            :extensions="extensions"
            :accept="accept"
            :multiple="multiple"
            :directory="directory"
            :create-directory="createDirectory"
            :size="size || 0"
            :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
            :headers="headers"
            :data="data"
            :drop="drop"
            :drop-directory="dropDirectory"
            :add-index="addIndex"
            v-model="files"
            @input-filter="inputFilter"
            @input-file="inputFile"
            ref="upload"
          >
            <i class="fa fa-plus"></i>
            Select
          </file-upload>
          <div class="dropdown-menu">
            <label class="dropdown-item" :for="name">Add files</label>
            <a class="dropdown-item" href="#" @click="onAddFolder"
              >Add folder</a
            >
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="addData.show = true"
              >Add data</a
            >
          </div>
        </div>
        <button
          type="button"
          class="btn btn-success"
          v-if="!$refs.upload || !$refs.upload.active"
          @click.prevent="$refs.upload.active = true"
        >
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          Start Upload
        </button>
        <button
          type="button"
          class="btn btn-danger"
          v-else
          @click.prevent="$refs.upload.active = false"
        >
          <i class="fa fa-stop" aria-hidden="true"></i>
          Stop Upload
        </button>
      </div>
    </div>

    <div
      :class="{ modal: true, fade: true, show: addData.show }"
      id="modal-add-data"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add data</h5>
            <button
              type="button"
              class="close"
              @click.prevent="addData.show = false"
            >
              <span>&times;</span>
            </button>
          </div>
          <form @submit.prevent="onAddData">
            <div class="modal-body">
              <div class="form-group">
                <label for="data-name">Name:</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  id="data-name"
                  placeholder="Please enter a file name"
                  v-model="addData.name"
                />
                <small class="form-text text-muted"
                  >Such as <code>filename.txt</code></small
                >
              </div>
              <div class="form-group">
                <label for="data-type">Type:</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  id="data-type"
                  placeholder="Please enter the MIME type"
                  v-model="addData.type"
                />
                <small class="form-text text-muted"
                  >Such as <code>text/plain</code></small
                >
              </div>
              <div class="form-group">
                <label for="content">Content:</label>
                <textarea
                  class="form-control"
                  required
                  id="content"
                  rows="3"
                  placeholder="Please enter the file contents"
                  v-model="addData.content"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                @click.prevent="addData.show = false"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      :class="{
        modal: true,
        fade: true,
        show: fileExistsModal.show,
      }"
      :style="
        fileExistsModal.show
          ? 'display: block; background-color: rgba(0,0,0,0.5);'
          : ''
      "
      id="modal-file-exists"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">File Already Exists</h5>
            <button
              type="button"
              class="close"
              @click.prevent="closeFileExistsModal"
            >
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              The file "<strong>{{ fileExistsModal.fileName }}</strong
              >" already exists. What would you like to do?
            </p>
          </div>
          <div class="modal-footer file-exists-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              @click.prevent="updateExistingFile()"
            >
              <i class="fa fa-refresh mr-1"></i>
              Update Existing File
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="createNewCandidate()"
            >
              <i class="fa fa-plus mr-1"></i>
              Create New Candidate
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.example-full .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all 0.2s;
}
.example-full .btn-group:hover > .dropdown-menu {
  visibility: visible;
}
.example-full label.dropdown-item {
  margin-bottom: 0;
}
.example-full .btn-group .dropdown-toggle {
  margin-right: 0.6rem;
}
.td-image-thumb {
  max-width: 4em;
  max-height: 4em;
}
.example-full .filename {
  margin-bottom: 0.3rem;
}
.example-full .btn-is-option {
  margin-top: 0.25rem;
}
.example-full .example-foorer {
  padding: 0.5rem 0;
}
.example-full .edit-image img {
  max-width: 100%;
}
.example-full .edit-image-tool {
  margin-top: 0.6rem;
}
.example-full .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}
.example-full .footer-status {
  padding-top: 0.4rem;
}
.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.tbody {
  background: #fff;
}
.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.modal.show {
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
}

body.modal-open {
  overflow: hidden;
}

.modal-lg {
  max-width: 800px !important;
  width: 90% !important;
  margin: 1.75rem auto !important;
}

.modal-content {
  width: 100% !important;
}

.file-exists-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 1rem;
  flex-wrap: wrap;
}

.file-exists-footer .btn {
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-body strong {
  font-weight: 600;
  color: #7367f0;
}
</style>

<script>
import Cropper from "cropperjs";
import ImageCompressor from "@xkeshi/image-compressor";
import FileUpload from "vue-upload-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
import router from "@/router";

export default {
  components: {
    FileUpload,
    ToastificationContent,
  },
  data() {
    return {
      files: [],
      accept: "pdf",
      extensions: /\.(pdf)$/i,
      // extensions: ['pdf'],
      // extensions: /\.(gif|jpe?g|png|webp)$/i,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      createDirectory: false,
      addIndex: false,
      thread: 3,
      name: "file",
      postAction: `${process.env.VUE_APP_BASE_URL}/api/Candidate/create`,
      //putAction: `${process.env.VUE_APP_BASE_URL}/api/Candidate/create`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      data: {},
      autoCompress: 1024 * 1024,
      uploadAuto: true,
      isOption: false,
      addData: {
        show: false,
        name: "",
        type: "",
        content: "",
      },
      editFile: {
        show: false,
        name: "",
      },
      length: 0,
      fileExistsModal: {
        show: false,
        fileName: "",
        fileToUpload: null,
        candidateId: null,
      },
      checkedFiles: new Set(),
    };
  },
  watch: {
    "editFile.show"(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || "",
        });
      }
      if (newValue) {
        this.$nextTick(() => {
          if (!this.$refs.editImage) {
            return;
          }
          let cropper = new Cropper(this.$refs.editImage, {
            autoCrop: false,
          });
          this.editFile = {
            ...this.editFile,
            cropper,
          };
        });
      }
    },
    "addData.show"(show) {
      if (show) {
        this.addData.name = "";
        this.addData.type = "";
        this.addData.content = "";
      }
    },
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (this.checkedFiles.has(newFile.name)) {
          return;
        }

        this.checkedFiles.add(newFile.name);

        this.checkFileExists(newFile, prevent);

        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression

        if (
          newFile.file &&
          newFile.error === "" &&
          newFile.type.substr(0, 6) === "image/" &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = "compressing";
          const imageCompressor = new ImageCompressor(null, {
            convertSize: 1024 * 1024,
            maxWidth: 512,
            maxHeight: 512,
          });
          imageCompressor
            .compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type,
              });
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, {
                error: err.message || "compress",
              });
            });
        }
      }
      if (
        newFile &&
        newFile.error === "" &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        // Create a blob field

        newFile.blob = "";
        let URL = window.URL || window.webkitURL;
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails

        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
      // image size

      if (
        newFile &&
        newFile.error === "" &&
        newFile.type.substr(0, 6) === "image/" &&
        newFile.blob &&
        (!oldFile || newFile.blob !== oldFile.blob)
      ) {
        newFile.error = "image parsing";
        let img = new Image();
        img.onload = () => {
          this.$refs.upload.update(newFile, {
            error: "",
            height: img.height,
            width: img.width,
          });
        };
        img.οnerrοr = (e) => {
          this.$refs.upload.update(newFile, { error: "parsing image size" });
        };
        img.src = newFile.blob;
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: "size" });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // this.files = [];
          // error message: "Unauthorized"
          const message = newFile?.xhr?.status;

          if (message && message === 401) {
          } else {
            for (const [key, value] of Object.entries(
              newFile.response.errors
            )) {
              this.$toast(
                {
                  component: ToastificationContent,
                  timeout: false,
                  props: {
                    title: "Error",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                    text: value,
                  },
                },
                { timeout: 6000 }
              );
            }
          }
        }
        if (newFile.success && !oldFile.success) {
          // success
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "AlertTriangleIcon",
              variant: "success",
              text: newFile.name,
            },
          });
          this.length = Number(this.length) + 1;
          if (this.length === this.files.length) router.push("/apps/list");
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Modify the auto-upload behavior
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        // Only auto-upload if it's not a new file being added
        // This prevents auto-upload when a file is first added
        if (
          this.uploadAuto &&
          !this.$refs.upload.active &&
          !(newFile && !oldFile)
        ) {
          this.$refs.upload.active = true;
        }
      }
    },
    alert(message) {
      alert(message);
    },
    onEditFileShow(file) {
      this.editFile = { ...file, show: true };
      this.$refs.upload.update(file, { error: "edit" });
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        this.alert("Your browser does not support");
        this.editFile.show = false;
        return;
      }
      let data = {
        name: this.editFile.name,
        error: "",
      };
      if (this.editFile.cropper) {
        let binStr = atob(
          this.editFile.cropper
            .getCroppedCanvas()
            .toDataURL(this.editFile.type)
            .split(",")[1]
        );
        let arr = new Uint8Array(binStr.length);
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        data.file = new File([arr], data.name, { type: this.editFile.type });
        data.size = data.file.size;
      }

      this.$refs.upload.update(this.editFile.id, data);
      this.editFile.error = "";
      this.editFile.show = false;
    },
    // add folder
    onAddFolder() {
      if (!this.$refs.upload.features.directory) {
        this.alert("Your browser does not support");
        return;
      }
      let input = document.createElement("input");
      input.style =
        "background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;";
      input.type = "file";
      input.setAttribute("allowdirs", true);
      input.setAttribute("directory", true);
      input.setAttribute("webkitdirectory", true);
      input.multiple = true;
      document.querySelector("body").appendChild(input);
      input.click();
      input.onchange = (e) => {
        this.$refs.upload.addInputFile(input).then(function () {
          document.querySelector("body").removeChild(input);
        });
      };
    },
    onAddData() {
      this.addData.show = false;
      if (!this.$refs.upload.features.html5) {
        this.alert("Your browser does not support");
        return;
      }
      let file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type,
      });
      this.$refs.upload.add(file);
      // router.push("/apps/list").catch(() => {});
      //  router.go("/apps/list").catch(() => {});
    },

    fileSizePipe(size) {
      if (size < 1024) {
        return size + " bytes";
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(1) + " KB";
      } else if (size < 1024 * 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(1) + " MB";
      } else {
        return (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      }
    },

    checkFileExists(file, prevent) {
      const fileName = file.name;

      this.fileExistsModal.fileToUpload = file;

      fetch(`${process.env.VUE_APP_BASE_URL}/api/Candidate/check-file-exists`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fileName),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("File exists check response:", data);

          if (data && data.exists === true) {
            console.log("File exists, showing modal");

            this.fileExistsModal.fileName = fileName;
            this.fileExistsModal.show = true;

            this.fileExistsModal.candidateId = data.candidateId;

            this.$nextTick(() => {
              console.log("Modal visibility state:", this.fileExistsModal.show);
              document.body.classList.add("modal-open");
            });

            prevent();
          } else {
            this.postAction = `${process.env.VUE_APP_BASE_URL}/api/Candidate/create`;

            this.$nextTick(() => {
              if (!this.$refs.upload.active) {
                this.$refs.upload.active = true;
              }
            });
          }
        })
        .catch((error) => {
          console.error("Error checking file existence:", error);

          this.postAction = `${process.env.VUE_APP_BASE_URL}/api/Candidate/create`;

          this.$nextTick(() => {
            if (!this.$refs.upload.active) {
              this.$refs.upload.active = true;
            }
          });
        });
    },

    updateExistingFile() {
      this.fileExistsModal.show = false;
      document.body.classList.remove("modal-open");

      const file = this.fileExistsModal.fileToUpload;

      if (file && file.file) {
        const candidateId = this.fileExistsModal.candidateId;

        const formData = new FormData();
        formData.append("file", file.file);

        axios
          .post(
            `${process.env.VUE_APP_BASE_URL}/api/Candidate/update-cv/${candidateId}`,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "AlertTriangleIcon",
                variant: "success",
                text: `File ${file.name} updated successfully`,
              },
            });

            router.push("/apps/list");
          })
          .catch((error) => {
            let errorMessage = "Failed to update file";

            if (
              error.response &&
              error.response.data &&
              error.response.data.errors
            ) {
              const errorDetails = Object.values(error.response.data.errors)
                .flat()
                .join(", ");
              errorMessage = `${errorMessage}: ${errorDetails}`;
            } else if (error.message) {
              errorMessage = `${errorMessage}: ${error.message}`;
            }

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: errorMessage,
              },
            });

            console.error(
              "Update error details:",
              error.response ? error.response.data : error
            );
          });
      }
    },

    createNewCandidate() {
      this.fileExistsModal.show = false;
      document.body.classList.remove("modal-open");

      this.postAction = `${process.env.VUE_APP_BASE_URL}/api/Candidate/create`;

      this.$nextTick(() => {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      });
    },

    closeFileExistsModal() {
      this.fileExistsModal.show = false;
      document.body.classList.remove("modal-open");

      if (this.fileExistsModal.fileToUpload) {
        const index = this.files.findIndex(
          (f) => f.id === this.fileExistsModal.fileToUpload.id
        );
        if (index !== -1) {
          this.files.splice(index, 1);
        }

        this.fileExistsModal.fileToUpload = null;
        this.fileExistsModal.fileName = "";
        this.fileExistsModal.candidateId = null;
      }
    },
  },
};
</script>